import styles from "./main.module.css";

export default function Footer() {
    return <div className="container">
        <div className={styles.box}>
            <p className="text-base larged:text-xl text-center md:max-w-full max-w-[230px]">&copy; 2024, Trading Mindset Ltd. All Rights Reserved</p>
            <div className="flex gap-4">
                <a href="https://x.com/tradingmindsets" target="_blank" className={styles.iconBox}>
                    <i className="fa-brands fa-twitter"></i>
                </a>

                <a href="https://www.instagram.com/trading_mindsets" target="_blank" className={styles.iconBox}>
                    <i className="fa-brands fa-instagram"></i>
                </a>

                <a href="https://youtube.com/@TradingMindset" target="_blank" className={styles.iconBox}>
                    <i className="fa-brands fa-youtube"></i>
                </a>
            </div>
        </div>
    </div>
}