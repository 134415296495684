import Cookies from "universal-cookie";
import styles from "./main.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Success() {
    
    const[link, setLink] = useState(null);

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(() => {
        const generated = cookies.get("generated");

        setLink(generated);

        setTimeout(() => {
            cookies.set("generated", null);
        }, 200);

        if (!generated || generated == null) {
            navigate("/");
        }
    }, []);

    if (!link || link == null) {
        return <svg className="w-full h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>;
    }

    return <div className="container">
        <img id="top-right-image" src="./bg-image-big.png" alt="Description of your image"></img>

        <div className={styles.wrapper}>
            <h1 className="text-2xl md:text-5xl font-bold align-top">The Inner Circle of Traders Welcomes You</h1>

            <a className="w-full sm:w-fit md:max-w-[fit-content]" href={link} target="_blank"><button className={`${styles.back} mt-6`}>Jump to the Discord Channel <i className="fa-solid fa-angle-right"></i></button></a>
        </div>

        <div className={styles.box}>

            <div className="flex flex-col justify-start gap-4 place-items-start w-full pr-20">
            
                <h1 className="text-xl text-[#22D3EE] font-semibold">Success!</h1>

                <p className="text-sm text-[#22D3EE]">Please note that the Discord invitation is uniquely generated and can only be redeemed once. Each User ID (UID) grants a singular access link, and cannot be reused to obtain another</p>
            
                <div className={styles.line}></div>

                <p className="text-sm text-[#22D3EE]">Discord Access: Analytical Trade Reviews, Live Engagement Sessions, Automated Bot Assistance, and Dedicated One-on-One Chat Features</p>
            </div>

        </div>
    </div>
}

export default Success;