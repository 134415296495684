import { useEffect, useState } from "react";
import styles from "../style/Dashboard.module.css";
import Cookies from 'universal-cookie';
import DataTable from 'react-data-table-component';

export default function Dashboard() {

    const cookies = new Cookies();

    const[data, setData] = useState(null);
    const[loading, setLoading] = useState(true);

    const[enteredPass, setEnteredPass] = useState(null);
    const[enteredApiKey, setEnteredApiKey] = useState(null);
    const[enteredApiKeySecret, setEnteredApiKeySecret] = useState(null);

    const[keysUpdateMessage, setKeysUpdateMessage] = useState(null);
    const[removeUserMessage, setRemoveUserMessage] = useState(null);

    const fetchAdminUser = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/adminUser`, {
            headers: {
                accessKey: cookies.get("access-key")
            }
        });

        const data = await response.json();
    
        setData({...data});
        setEnteredApiKey(data.apiKey);
        setEnteredApiKeySecret(data.apiKeySecret);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const updateKeys = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/adminUser/updateKeys`, {
            method: "POST",
            headers: {
                accessKey: cookies.get("access-key"),
                apiKey: enteredApiKey,
                apiKeySecret: enteredApiKeySecret
            },
            body: {}
        });

        const data = await response.json();

        if (data.message) {
            setKeysUpdateMessage(data.message);
        } else {
            setKeysUpdateMessage("We failed to update keys on the server");
        }

        setTimeout(() => {
            setKeysUpdateMessage(null);
        }, 3000);
    }

    
    const removeUid = async (uid) => {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/adminUser/removeUid/${uid}`, {
            method: "POST",
            headers: {
                accessKey: cookies.get("access-key")
            },
            body: {}
        });

        const data = await response.json();

        fetchAdminUser().then(() => {
            setTimeout(() => {
                if (data.message) {
                    setRemoveUserMessage(data.message);
                } else {
                    setRemoveUserMessage("We failed to remove UID from the database");
                }
            }, 400);
        });

  
        setTimeout(() => {
            setRemoveUserMessage(null);
        }, 3000);
    }

    useEffect(() => {
        fetchAdminUser();
    }, []);


    if (loading) {
        return <div className={styles.loader}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>
        </div>
    }

    if (!data || data.status == "no_access") {
        return <div className={`${styles.container} ${styles.noAccess}`}>
            <h1>You don't have access to dashboard</h1>
            <p>Enter password bellow to gain access</p>

            <form onSubmit={(event) => {
                event.preventDefault(); // Prevent default form submission
                cookies.set("access-key", enteredPass);
                window.location.reload(); // Reload the page
            }}>

                <input type="password" name="password_input" id="password_input" placeholder="Enter password" onChange={(event) => {
                    setEnteredPass(event.target.value);
                }}/>

                <button type="submit">Login</button>
            </form>
        </div>
    }

    return (
        <div className={`${styles.container} ${styles.access}`}>
            <div className={styles.welcome}>
                <h1>Welcome to the dashboard</h1>
                <button onClick={() => {
                    cookies.set("access-key", null);
                    window.location.reload(); // Reload the page
                }}>Logout</button>
            </div>

            <p>{keysUpdateMessage}</p>

            <form onSubmit={(event) => {
                event.preventDefault(); // Prevent default form submission
                updateKeys();
            }}>

                <input type="text" name="api_key_input" id="api_key_input" placeholder="Enter API Key" onChange={(event) => {
                    setEnteredApiKey(event.target.value);
                }} defaultValue={enteredApiKey}/>

                <input type="text" name="api_key_secret_input" id="api_key_secret_input" placeholder="Enter API Key Secret" onChange={(event) => {
                    setEnteredApiKeySecret(event.target.value);
                }} defaultValue={enteredApiKeySecret}/>

                <button type="submit" className="text-[white] hover:translate-x-2">Update API Keys</button>

            </form>
            <h4>List of uids that gained access to the discord server</h4>

            <div className={styles.listBox}>
                {data?.invites.map(invite => {
                    if (invite.memberName == null) {
                        return <div key={invite.uid} className="flex items-center mb-4">
                                    <div key={invite.uid} className="h-2">{`UID (${invite.uid}) - Invite is genereated but not used`}</div>
                                    <button className="h-2 text-[#f68686] hover:text-[red] transition-all duration-300" onClick={() => removeUid(invite.uid)}>Click to remove</button>
                            </div>
                    } else {
                        return  <div key={invite.uid} className="flex items-center mb-4">
                                        <div key={invite.uid} className="h-2">{`UID (${invite.uid}) - Invite link was used by ${invite.memberUsername}`}</div>
                                        <button className="h-2 text-[#f68686] hover:text-[red] transition-all duration-300" onClick={() => removeUid(invite.uid)}>Click to remove</button>
                            </div>
                    }
                })}
            </div>

            <p className="text-[#f68686] mt-1">{removeUserMessage}</p>

        </div>
    )
}