import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './dashboard/Dashboard';
import MainPage from './main/MainPage';
import CreateAccount from './create/CreateAccount';
import Trade from './trade/Trade';
import Success from './success/Success';
import Footer from './footer/Footer';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
    errorElement: <h1>Error page</h1>,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/main',
    element: <MainPage />,
  },
  {
    path: '/create-account',
    element: <CreateAccount />,
  },
  {
    path: '/create-trade',
    element: <Trade />,
  },
  {
    path: '/success',
    element: <Success />,
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Footer></Footer>
  </React.StrictMode>
);