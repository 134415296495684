import styles from "./main.module.css";

function Trade() {
    
    return <div className="container">
        <img id="top-right-image" src="./bg-image-big.png" alt="Description of your image"></img>

        <div className={styles.wrapper}>
            <h1 className="text-2xl md:text-5xl font-bold align-top">The Inner Circle of Traders Welcomes You</h1>
            <p className="text-[16px] md:text-xl mt-6 text-[#F7A700]">Your journey to premium features is just one step away.</p>

            <a href="/"><button className={styles.back}><i className="fa-solid fa-angle-left"></i>Back & Paste UID</button></a>
        </div>

        <div className={styles.box}>

            <div className="flex lg:flex-row justify-evenly w-full pr-0 md:pr-20 flex-col">
            
                <div className="flex gap-3 items-start w-full flex-col">
                    <h1 className="text-3xl font-bold align-top max-w-[42rem] w-full">Execute a trade exceeding $10 on your Bybit account within the last 30 days. </h1>
                    <p className="text-base mt-4 text-[#F3E8FF]">Execute a trade exceeding $10 on your Bybit account within the last 30 days. </p>
                </div>

                <a className="w-full sm:w-fit md:max-w-[229px]" href="https://www.bybit.com/trade/usdt/BTCUSDT?affiliate_id=3239" target="_blank"><button className={styles.tradeButton}>Execute a trade<i className="fa-solid fa-angle-right"></i></button></a>
            </div>

        </div>
    </div>
}

export default Trade;