import styles from "./main.module.css";

function CreateAccount() {
    
    return <div className="container">
        <img id="top-right-image" src="./bg-image-big.png" alt="Description of your image"></img>

        <div className={styles.wrapper}>
            <h1 className="text-2xl md:text-5xl font-bold align-top">Activate Your Community Privileges</h1>
            <p className="text-[16px] md:text-xl mt-6 text-[#F7A700]">Affiliate Confirmation Required</p>

            <a href="/"><button className={styles.back}><i className="fa-solid fa-angle-left"></i>Back & Paste UID</button></a>
        </div>

        <div className={styles.box}>
            <div className={styles.image}>
            <iframe width="1189" height="669" src="https://www.youtube.com/embed/UDUgIxf0KWQ?start=1059" title="Bybit Futures Trading Guide 2024 (Order Types, Hedging, Identity Transfer)" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className="flex flex-col justify-evenly">
                <h1 className="text-3xl font-bold align-top max-w-[37rem]">It seems like your Bybit account was not created using our affiliate link</h1>
                <p className="text-xl mt-4">To get FREE access, your Bybit account must be created with our Bybit link</p>
            
            
                <div className="flex gap-3 mt-4 flex-wrap">
                    <a className="w-full sm:w-fit md:max-w-[260px]" href="https://link.tradingmindset.in/bybit" target="_blank"><button className={styles.createAccountButton}>Create a new Bybit account</button></a>
                    <a className="w-full sm:w-fit md:max-w-[163px]" href="mailto:hello@tradingmindset.in"><button className={styles.helpButton}>Need help?<i className="fa-solid fa-angle-right"></i></button></a>
                </div>
            </div>

        </div>
    </div>
}

export default CreateAccount;