import { useState } from "react";
import styles from "./main.module.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

function MainPage() {
    
    const[uid, setUid] = useState(null);
    const[loading, setLoading] = useState(false);
  
    const[data, setData] = useState(null);
    const[responseMessage, setResponseMessage] = useState(null);
  
    const cookies = new Cookies();

    const navigate = useNavigate()

    const fetchUid = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/check/${uid}`);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          
          const data = await response.json();
          setData({...data});
                    
          return data; // Return data if request is successful
        } catch (error) {
          console.error('Error fetching data:', error);
          return null; // Return null if request fails
        }
      };

    return <div className="container">
        <img id="top-right-image" src="./bg-image.png" alt="Description of your image"></img>

        <div className={styles.wrapper}>
            <h1 className="text-2xl md:text-5xl larged:text-7xl font-bold align-top">Exclusive Discord Community Access</h1>
            <a href="http://link.tradingmindset.in/bybit" target="_blank" className="text-[16px] font-semibold underline md:text-xl larged:text-4xl mt-6 text-[#F7A700]">Connect your Bybit Account</a>

            <div className="mt-8 flex flex-col items-start gap-4">
                <div className="flex items-center gap-[14px] justify-center">
                    <img src="./checkbox.png" alt="Checkbox icon" className="w-4 h-4 mt-[2px] larged:w-6 larged:h-6"/>
                    <p className="text-base larged:text-2xl">Sign in to your Bybit account</p>
                </div>

                <div className="flex items-center gap-[14px] justify-center">
                    <img src="./checkbox.png" alt="Checkbox icon" className="w-4 h-4 mt-[2px] larged:w-6 larged:h-6"/>
                    <p className="text-base larged:text-2xl">Navigate to your profile by clicking on your username at the top-right corner of the Bybit dashboard</p>
                </div>

                <div className="flex items-center gap-[14px] justify-center">
                    <img src="./checkbox.png" alt="Checkbox icon" className="w-4 h-4 mt-[2px] larged:w-6 larged:h-6"/>
                    <p className="text-base larged:text-2xl">Locate and copy your User ID (UID)</p>
                </div>
            </div>

            <div className="mt-12 flex flex-col w-full justify-center">                
                <div className="flex items-end justify-evently flex-wrap md:justify-start gap-3">
                    <div className="flex flex-col w-full sm:w-fit">
                        <label className="text-sm p-1 justify-self-start larged:text-base">Enter Bybit UID</label>
                        <input type="text" placeholder="Paste your UID here" onChange={(event) => {
                            setUid(event.target.value);
                        }}/>
                    </div>
            
                    {!loading && <button onClick={async () => {
                        if (!uid || uid == null || uid.length <= 0) {
                            setResponseMessage("Please enter your UID.");
                            setLoading(false);
                            return;
                        }

                        setLoading(true);
                        setResponseMessage(null);

                        const data = await fetchUid(uid);

                        if (data == null) {
                            setResponseMessage("We failed to check this UID. Try again or contact an admin.");
                            setLoading(false);
                        } else {
                            setTimeout(() => {
                                setLoading(false);

                                const status = data.status;

                                if (status == "not_used") {
                                    navigate("/create-account");
                                    return;
                                }
    
                                if (status == "already_used") {
                                    setResponseMessage("This UID was already used to generate an invite.");
                                    return;
                                }
    
                                if (status == "does_not_meet_condition") {
                                    navigate("/create-trade");
                                    return;
                                }
    
                                if (status == "generated") {
                                    cookies.set("generated", data.link);
                                    navigate("/success");
                                    return;
                                }
                            }, 1500);
                        }

                    }}>Unlock My Access</button>}

                    {loading && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#4A50FF" stroke="#4A50FF" strokeWidth="16" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1.9" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>}
                </div>

                {responseMessage && <p className="text-sm text-[red] mt-2 text-center md:text-left">{responseMessage}</p>}
            </div>
        </div>
    </div>
}

export default MainPage;